import React, { useEffect, useState } from 'react'
import LessonCard from '../../components/Card/lessencard'
import { getaddressuri, getlesson } from '../../uri'
import axios from 'axios'
import AddressComp from '../../components/Card/address'

const Address = () => {
  const [load, setLoad] =useState(false)
  const [address, setAddress] = useState([])

  useEffect(() => {
    loadProfile();
  },[]);

  const loadProfile = async () => {
    setLoad(true);
    try{
      const res = await axios.get(getaddressuri);
      setAddress(res.data);
      setLoad(false);
    }catch(err){
      console.log(err)
      setLoad(false)
    }
  }


  return (
    <div>
        <div className='mt-20'>
            <div className='h-[20vh] bg-juram bg-cover'>
                <div className='container'>
                    <h1 className='font-Roboto font-extrabold text-3xl uppercase pt-6 md:block md:pt-20 text-center'>Хаяг холбох</h1>
                </div>
            </div>
            <div className='xs:block md:container py-16'>
                <div className='grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 container gap-4'>
                  {
                    address.map((data, index) => {
                      return(
                        <AddressComp
                          key={index}
                          data={data}
                        />
                      )
                    })
                  }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Address