import React, { useContext, useEffect } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Button,
    ModalCloseButton,
    Input,
    Spinner,
    useToast
  } from '@chakra-ui/react' ;
import { deliveryRegisteruri, userdelivcount } from '../../uri';
import { AuthContext } from '../../context/auth' ;
import { useState } from 'react' ;
import axios from 'axios' ;


const AllDelivery = ({callback}) => {
  const toast = useToast();
    const {currentUser, logout} = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const[data, setData] = useState([]);
    const[loading, setLoading] = useState(false);
    const[phone, setPhone] = useState()
    const pays = (data.reduce((a,v) =>  a = a + v.pay , 0 ))

    const dates = new Date();
    dates.setHours(dates.getHours() + 8)

    const handleCallback = () => callback()

    useEffect(() => {
        const fetchData = async ()=>{
          try{
            const resss= await axios.get(userdelivcount + currentUser)
            setData(resss.data)
          }catch(err){
            console.log(err)
          }
        };
        fetchData();
      },[]);

      const Submit = async () => {
        if(!data.length){
          return toast({title: 'Таньд ирсэн бараа байхгүй байна !',description: "Хурд карго -г сонгодогт баярлалаа",status: 'warning',duration: 3000,isClosable: true,})
        }
        if(!phone){
          return toast({title: 'Та нэмэлт утасны дугаараа оруулна уу !',description: "Хурд карго -г сонгодогт баярлалаа",status: 'warning',duration: 3000,isClosable: true,})
        }
        setLoading(true);
        try{
          const res = await axios.post(deliveryRegisteruri, {asdf: currentUser, date: dates, helpPhone: phone})
          if(res.status === 204){
            setLoading(false);
            return toast({title: 'Уучлаарай ! Эрхэм харилцагчаа',description: "Таны хүргэлтэнд бүртгүүлсэн барааны хүргэлтийн төлбөр бодогдож баталгаажсан байна. Та эхний баталгаажсан хүргэлтээ хүлээн авсны дараа дараагийн бараагаа хүргэлтэнд бүртгүүлэх боломжтой болно !",status: 'warning',duration: 9000,isClosable: true,})
          }
          if(res.status === 200){
            toast({title: 'Амжилттай Бүртгэгдлээ',description: "Хурд карго -г сонгодогт баярлалаа",status: 'success',duration: 3000,isClosable: true,})
            setLoading(false);
            handleCallback();
            return onClose();
          }else{
            toast({title: 'Ямар нэг алдаа гарлаа',description: "Хурд карго -г сонгодогт баярлалаа",warning: 'success',duration: 3000,isClosable: true,})
            setLoading(false);
          }
         
        }catch(err){
          console.log(err)
          setLoading(false)
          toast({title: 'Алдаа гарлаа',description: "Хурд карго -г сонгодогт баярлалаа",status: 'error',duration: 3000,isClosable: true,})
        }
      };

  return (
    <>
      <Button onClick={onOpen} className='shadow-xl bg-indigo-600'>Бүх барааг хүргэлтээр авах</Button>

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Хүргэлт</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
             {/* <h1 className='text-xs text-start'>Та өөр дээрээ ирсэн бүх барааг хүргэлтээр авах гэж байна </h1> */}
             <div className='flex mt-4'>
                <h1 className='flex gap-2'>Хүргэлтээр авах бараа <p className='font-semibold'>{data.length}</p></h1>
             </div>
             
             <Input className='mt-4' placeholder='Нэмэлт утасны дугаар' onChange={e=> setPhone(e.target.value)}/>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Буцах
            </Button>
            {
                loading?
                <Button colorScheme='green'><Spinner size='sm' className="mr-2"/>Хүргэлтэнд бүртгүүлэх</Button>
                :
                <Button onClick={Submit} colorScheme='green'>Хүргэлтэнд бүртгүүлэх</Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AllDelivery