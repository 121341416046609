import React from 'react'
import {BiSolidUser} from 'react-icons/bi'
import PersonalUpdate from '../../../components/Modal/Update/personal'

const UserProfile = ({data}) => {
  return (
    <div className=''>
        <div className='bg-[#274595] rounded-xl p-8 h-full'>
            <div className='flex justify-between '>
                <div className='flex items-center gap-4 flex-justify-between'>
                    <div className='bg-[#F3D080] rounded-full p-2'>
                        <BiSolidUser color='white' size={25}/>
                    </div>
                    <div className='text-white'>
                        <p className='text-opacity-75 text-lg'>Хувийн мэдээлэл</p>
                        <h1 className='font-bold text-xl'>{data.tabaoName}</h1>
                    </div>
                </div>
                <div>
                    <PersonalUpdate data={data}/>
                </div>
            </div>
            <div className='text-white '>
                <p className='text-opacity-75 text-lg mt-4'>Хаягийн нэр</p>
                <h1 className='font-bold text-xl'>{data.tabaoName}</h1>

                <p className='text-opacity-75 text-lg mt-4'>Имейл хаяг</p>
                <h1 className='font-bold text-xl'>{data.email}</h1>

                <p className='text-opacity-75 text-lg mt-4'>Утас</p>
                <h1 className='font-bold text-xl'>{data.phoneNo}</h1>
            </div>
        </div>
    </div>
  )
}

export default UserProfile