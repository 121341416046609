import React, { useEffect, useState } from 'react'
import LessonCard from '../../components/Card/lessencard'
import { getlesson } from '../../uri'
import axios from 'axios'

const Lessons = () => {
  const [load, setLoad] =useState(false)
  const [lesson, setLesson] =useState([])

  useEffect(() => {
    loadProfile();
  },[]);

  const loadProfile = async () => {
    setLoad(true);
    try{
      const res = await axios.get(getlesson);
      setLesson(res.data);
      setLoad(false);
    }catch(err){
      console.log(err)
      setLoad(false)
    }
  }


  return (
    <div>
        <div className='mt-20'>
            <div className='h-[20vh] bg-juram bg-cover'>
                <div className='container'>
                    <h1 className='font-Roboto font-extrabold text-3xl uppercase pt-6 md:block md:pt-20 text-center'>Зөвлөгөө хичээлүүд</h1>
                </div>
            </div>
            <div className='xs:block md:container py-16'>
                <div className='grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 container gap-4'>
                  {
                    lesson.map((data, index) => {
                      return(
                        <LessonCard key={index} data={data}/>
                      )
                    })
                  }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Lessons