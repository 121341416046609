import React, { useContext } from 'react'
import Sidebar from './Accs/sidebar'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from '../../context/auth'

const Account = () => {
  const {currentUser} = useContext(AuthContext);

  if(currentUser){
    return (
      <div className='bg-white font-Roboto mt-20'>
          <div className='xs:mx-4 xl:container py-8'>
            <div className='grid grid-cols-1 xl:grid-cols-5 gap-4'>

              <div className='grid col-span-1'>
                <Sidebar/>
              </div>
              
              <div className='grid col-span-4 w-full overflow-x-auto'>
                <div className='mx-4 md:block'>
                  <Outlet/>
                </div>
              </div>

            </div>
          </div>
      </div>
    )
  }else{
    return <Navigate to='/login'/>
  }
}

export default Account