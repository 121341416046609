import React, { useContext, useEffect, useState } from 'react'
import UserProfile from '../Components/userprofile'
import AddressComp from '../Components/address'
import AllOrders from '../Components/allorders'
import { getuseruri } from '../../../uri'
import { AuthContext } from '../../../context/auth'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'

const AccDashboard = () => {
  const {currentUser, logout} = useContext(AuthContext);
  const [load, setLoad] = useState(false)
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    setLoad(true)
    try{
        const res = await axios.get(getuseruri+`?asdf=${currentUser}`)
        setUser(res.data);
        setLoad(false)
    }catch(err){
        setLoad(false)
        console.log(err.response.data.error);
        toast({title: 'Та дахин нэвтэрнэ үү',description: "Токен хугацаа дууссан байна.",status: 'error',duration: 3000,isClosable: true,})
        logout();
        navigate("/login")
    }
  }

  return (
    <div className='font-Roboto'>
      <div className='bg-slate-100 shadow-xl rounded-xl py-4 md:py-8 px-8 md:px-20'>
        <div className='font-Roboto text-lg md:text-3xl font-bold flex items-center gap-3 flex-wrap'>
          <h1>Сайн байна уу</h1>
          <h1 className='bg-[#F3D080] text-white py-2 px-8'>{user.tabaoName}</h1>
        </div>
        <p className='text-opacity-80 mt-4'>Хурд каргонд тавтай морилно уу </p>
      </div>
      <div>
        <h1 className='font-bold text-xl mt-6'>Хувийн мэдээлэл</h1>
        <div className='mt-4'>
          <div className='grid xs:grid-cols-1 md:grid-cols-3 gap-3'>
            <div className='grid col-span-1'>
              <UserProfile data={user}/>
            </div>
            <div className='grid col-span-1'>
              <AddressComp data={user}/>
            </div>
            <div className='grid col-span-1'>
              <AllOrders data={user}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccDashboard