import React from 'react'
import {CiLocationOn} from 'react-icons/ci'
import {AiOutlineInstagram, AiOutlineMail, AiOutlineTwitter} from 'react-icons/ai'
import {BsTelephone} from 'react-icons/bs'
import {FaFacebookF} from 'react-icons/fa'
import { Link } from 'react-router-dom';
import {AspectRatio} from '@chakra-ui/react'

const Footer = () => {
  return (
    <div className='font-Roboto'>
        <div className=' xs:h-[100vh] md:h-[50vh] bg-footerback bg-cover'>
            <div className='container py-6 flex justify-center'>
                <div className='grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 mt-4'>
                    <div>
                        <img className='h-20' src='./images/logowhite.png'/>
                        <div className='flex gap-2 mt-4'>
                            <CiLocationOn size={40} color='white'/>
                            <p className='text-slate-300 font-semibold'>Улаанбаатар хот
                                Хан-уул дүүрэг 15-р хороо
                                120 мянгат Наадам центрийн урд цэнгэлдэх хотхон 212 байр
                            </p>
                        </div>
                        <div className='flex gap-2 mt-4'>
                            <AiOutlineMail size={20} color='white'/>
                            <p className='text-slate-300 font-semibold'>khurdcargo@gmail.com</p>
                        </div>
                        <div className='flex gap-2 mt-4'>
                            <BsTelephone size={20} color='white'/>
                            <p className='text-slate-300 font-semibold'>99033629</p>
                        </div>
                        <div className='flex gap-2 mt-4 items-center'>
                            <FaFacebookF size={26} color='white'/>
                            <AiOutlineInstagram size={30} color='white'/>
                            <AiOutlineTwitter size={30} color='white'/>
                        </div>
                    </div>
                    <div className='mt-4 flex flex-col'>
                        <h1 className='font-bold text-xl text-white mb-10'>Links</h1>
                        <Link to='/' className='text-lg text-slate-300 font-semibold'>Нүүр хуудас</Link>
                        <Link to='/lessons' className='text-lg text-slate-300 font-semibold mt-2'>Зөвлөгөө</Link>
                        <Link to='/service-procedure' className='text-lg text-slate-300 font-semibold mt-2'>Үйлчилгээний нөхцөл</Link>
                    </div>
                    <div className='mt-4'>
                        <h1 className='font-bold text-xl text-white mb-10'>Сүүлийн сургалт</h1>
                        <Link to='/lessons' className='flex items-center gap-4'>
                            <img className='rounded-xl h-14 w-14' src='https://live.staticflickr.com/714/23085553575_582b51a2b1_b.jpg'/>
                            <div>
                                <h1 className='text-slate-300 text-sm'>June 4, 2023</h1>
                                <h1 className='text-slate-100 text-lg font-bold'>Трак кодоо хэрхэн бүртгүүлэх вэ?</h1>
                            </div>
                        </Link>
                        <Link to='/lessons' className='flex items-center gap-4 mt-4'>
                            <img className='rounded-xl h-14 w-14' src='https://live.staticflickr.com/714/23085553575_582b51a2b1_b.jpg'/>
                            <div>
                                <h1 className='text-slate-300 text-sm'>June 4, 2023</h1>
                                <h1 className='text-slate-100 text-lg font-bold'>Трак кодоо хэрхэн бүртгүүлэх вэ?</h1>
                            </div>
                        </Link>
                    </div>
                    <div className='mt-4'>
                        <h1 className='font-bold text-xl text-white mb-10'>Байршил</h1>
                        <div>
                        <AspectRatio>
                            <iframe src='https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2674.829461887496!2d106.912931!3d47.900985999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDfCsDU0JzAzLjYiTiAxMDbCsDU0JzQ2LjYiRQ!5e0!3m2!1sen!2smn!4v1694188920277!5m2!1sen!2smn'/>
                        </AspectRatio>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer