import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import {HiHome} from "react-icons/hi"
import {AiOutlineMenu, AiOutlineClose} from "react-icons/ai"
import NavItem from './item';
import { AuthContext } from '../../context/auth';

const Navbar = ({children}) => {

    const {currentUser} = useContext(AuthContext);
    const location = useLocation();
    const pathname = location.pathname;

    const routes = useMemo(() => [
        {
            icon: HiHome,
            label:'Нүүр',
            activate: pathname === '/',
            href: '/'
        },
        {
            icon: HiHome,
            label:'Зөвлөгөө',
            activate: pathname === '/lessons',
            href: '/lessons'
        },
        {
            icon: HiHome,
            label:'Хаяг холбох',
            activate: pathname === '/address',
            href: '/address'
        },
        {
            icon: HiHome,
            label:'Үйлчилгээний нөхцөл',
            activate: pathname === '/service-procedure',
            href: '/service-procedure'
        },
        
    ], [pathname]);
    const routesAuth = useMemo(() => [
        {
            icon: HiHome,
            label:'Нэвтрэх',
            activate: pathname === '/login',
            href: '/login'
        },
        {
            icon: HiHome,
            label:'Бүртгүүлэх',
            activate: pathname === '/register',
            href: '/register'
        },
        
    ], [pathname]);

    const [nav, setNav] = useState(false);
    const [shadow, setShadow] = useState(false);

    const handleNav = () => {
        setNav(!nav)
    }

    useEffect(()=> {
        const handleShadow = () => {
            if (window.scrollY >= 90 ){
                setShadow(true)
            } else{
                setShadow(false)
            }
        };
        window.addEventListener('scroll', handleShadow)
    },[])

  return (
    <div className={pathname === '/login' || pathname === '/register' || pathname === '/forget-password' ? "font-Roboto hidden":"font-Roboto"}>

    <div className={pathname === '/' ? shadow? 'fixed top-0 w-full py-2 shadow-md z-[100] bg-white transition delay-75' : 'fixed top-0 w-full py-2 z-[100] transition delay-75' : 'fixed top-0 w-full py-2 z-[100] bg-white shadow-md transition delay-75' }>
        <div className='flex justify-between items-center xs:mx-2 md:container'>
            <div className=''>
                {
                    pathname === '/' && shadow ?
                        <Link to='/' className='flex items-center'>
                        <img className='h-16' src='../images/logoblack.png'/>
                        </Link>
                    :
                    pathname === '/' ?
                    <Link to='/' className='flex items-center'>
                        <img className='h-16' src='../images/logowhite.png'/>
                    </Link>
                    :
                    <Link to='/' className='flex items-center'>
                        <img className='h-16' src='../images/logoblack.png'/>
                    </Link>
                }
            </div>
           
            <div className={pathname === '/' ? shadow ? 'text-slate-700' : 'text-slate-200' : 'text-slate-700'}>
                <ul className='hidden md:flex md:mr-10 md:items-center'>
                    {routes.map((item) => (
                        <NavItem
                            key={item.label}
                            {...item}
                        />
                    ))}
                </ul>
                <div className='flex items-center gap-4'>
                    <Link to='/account' className='text-white md:hidden block'>
                        <img className='h-10 w-10 rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFY8X8BDSNJmIX2UR4V2uTjzpmmfGYnp3W5UuiEDWHP90PFOE1HBC96ZGDY5mH-nfButE&usqp=CAU'/>
                    </Link>
                    <div onClick={handleNav} className='md:hidden cursor-pointer mr-6'>
                        <AiOutlineMenu size={25}/>
                    </div>
                </div>
            </div>
            
            {
                currentUser?
                <Link to='/account' className=
                    {
                        pathname === '/' && shadow ?
                        'text-black hidden md:block'
                        :
                        pathname === '/' ?
                        'text-white hidden md:block'
                        :
                        'text-black hidden md:block'
                    }
                >

                    <div className='flex items-center gap-4'>
                        <img className='h-10 w-10 rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFY8X8BDSNJmIX2UR4V2uTjzpmmfGYnp3W5UuiEDWHP90PFOE1HBC96ZGDY5mH-nfButE&usqp=CAU'/>
                        <div>
                            <div className='font-Roboto'>Hello</div>
                            <div className='font-Roboto font-bold '>Миний хуудас</div>
                        </div>
                    </div>
                </Link>
                :
                <div className="ml-6 hidden md:block">
                    <div className={pathname === '/' ? shadow? 'text-slate-700' : 'text-slate-200' : 'text-slate-700'}>
                        {/* <Link to='/login' className=''>Нэвтрэх</Link>
                        <Link to='/register' className='ml-4'>Бүртгүүлэх</Link> */}
                        <ul className='hidden md:flex md:mr-10 md:items-center'>
                            {routesAuth.map((item) => (
                                <NavItem
                                    key={item.label}
                                    {...item}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            }

        </div>

        <div className={nav ? 'md:hidden fixed left-0 top-0 w-full h-screen bg-black/70' : ''}>
            <div className={ nav ? 'md:hidden fixed left-0 top-0 w-[75%] sm:w-[60%] md:w-[45%] h-screen bg-white p-10 ease-in duration-300' 
                                 : 'fixed left-[-100%] top-0 p-10 ease-in duration-300'}>
                <div>
                    <div className='flex w-full items-center justify-between'>
                       <img className=' w-20' src='./images/logoblack.png'/>
                        <div onClick={handleNav} className='rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer'>
                           <AiOutlineClose/>
                        </div>
                    </div>
                </div>
                <div className='py-4 flex flex-col'>
                    <ul className='uppercase'>
                    {routes.map((item) => (
                        <NavItem
                            key={item.label}
                            {...item}
                        />
                    ))}
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div>
        {children}
    </div>

</div>
  )
}

export default Navbar