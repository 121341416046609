import React, { useContext, useState } from 'react'
import { HiOutlineHome } from 'react-icons/hi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {BsBox2Heart} from 'react-icons/bs'
import {BiLogOutCircle} from 'react-icons/bi'
import { AuthContext } from '../../../context/auth';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Spinner,
} from '@chakra-ui/react'

const Sidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
    const {logout} = useContext(AuthContext);
    const location = useLocation();
    const pathname = location.pathname;
    const navigate = useNavigate();
    const [loading , setLoading] = useState(false);

    function Logout(){
      setLoading(true);
      logout();
      navigate("/login")
      setLoading(false);
    }

  return (
    <div>
        <Link to='/account' className={pathname === "/account" ? 'flex items-center gap-4 text-white ml-4 bg-[#2353A0] rounded-md p-2 hover:bg-opacity-75':
            'flex items-center gap-4 text-black ml-4 p-2 mt-2 hover:text-opacity-70 group'}>
            <HiOutlineHome color={pathname === "/account" ? 'white':'black'} size={25}/>
            <h1>Нүүр</h1>
        </Link>
        <Link to='/account/orders' className={pathname === "/account/orders" ? 'flex items-center gap-4 text-white ml-4 bg-[#2353A0] rounded-md p-2 hover:bg-opacity-75':
            'flex items-center gap-4 text-black ml-4 p-2 mt-2 hover:text-opacity-70 group'}>
            <BsBox2Heart color={pathname === "/account/orders" ? 'white':'black'} size={22}/>
            <h1>Захиалга</h1>
        </Link>

        <div className='p-2 ml-4 flex items-center gap-4 mt-10 cursor-pointer '>
          <BiLogOutCircle size={25}/>
          <h1 onClick={onOpen}>Системээс гарах</h1>
        </div>

        <Modal isOpen={isOpen} onClose={onClose} className='font-Roboto'>
          <ModalOverlay />
            <ModalContent>
            <ModalHeader>Системээс гарах</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <h1 className='font-Roboto text-xl font-bold'>Та системээс гарахдаа итгэлтэй байна уу ?</h1>
            </ModalBody>

            <ModalFooter>
              <Button variant='ghost' mr={3} onClick={onClose}>
                Үгүй
              </Button>
              <Button colorScheme='blue' onClick={Logout}>
                {
                  loading&&
                  <Spinner className='mr-2'/>
                }
                 Тийм</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

    </div>
  )
}

export default Sidebar