import React, { useContext, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Input,
    Spinner,
    useToast,
  } from '@chakra-ui/react'

import axios from 'axios'
import { usertrackadd } from '../../uri'
import { AuthContext } from '../../context/auth'

const AddTrack = ({callback}) => {
    const {currentUser} = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [note, setNote] = useState(null)
    const [track, setTrack] = useState(null)
    const [load, setLoad] = useState(false)
    const toast = useToast();

    const handleCallback = () => callback();

    const HandleSubmit = async e => {
        setLoad(true)
        if(track == null){
          setLoad(false)
          return toast({title: 'Алдаа',description: "Та трак кодоо оруулна уу !",status: 'error',duration: 3000,isClosable: true,})
         }
         if(note == null){
          setLoad(false)
          return toast({title: 'Алдаа',description: "Та тэмдэглэлээ оруулна уу !",status: 'error',duration: 3000,isClosable: true,})
         }
         try{
           const res = await axios.post(usertrackadd, { trackCode: track, note:note, asdf: currentUser })
           if(res.status === 200){
            setLoad(false)
            onClose();
            handleCallback();
            return toast({title: 'Амжилттай',description: "Таны трак код бүртгэгдлээ !",status: 'success',duration: 3000,isClosable: true,})
           }
         }catch(err){
           console.log(err)
           setLoad(false);
           return toast({title: 'Алдаа',description: "Та системээс гараад нэвтэрнэ үү !",status: 'error',duration: 3000,isClosable: true,})
         }
    }

  return (
    <>
      <div onClick={onOpen} className='font-bold h-8 px-2 hover:text-opacity-60 hover:bg-opacity-70 font-Roboto text-white text-sm flex items-center cursor-pointer rounded-md shadow-xl bg-[#2353A0]
        gap-2'>
        <img className='w-4 md:w-6' src='../icons/add-white.png'/>
          <h1 className='text-xs md:text-sm'>Захиалгын код оруулах</h1>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Захиалгын код оруулах</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
               <div className='font-Roboto'>
                   <h1>Захиалгын код</h1>
                   <Input onChange={(e) => setTrack(e.target.value)} className='mt-1' placeholder='Захиалгын код оруулах'/>
                   <h1 className='mt-2'>Тэмдэглэл</h1>
                   <Input onChange={(e) => setNote(e.target.value)} className='mt-1' placeholder='Тэмдэглэл оруулах'/>
               </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={onClose}>
              Болих
            </Button>
            <Button colorScheme='blue' mr={3} onClick={HandleSubmit}>
            {
                load&&
                <Spinner className='mr-2'/>
            }
              Оруулах
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddTrack