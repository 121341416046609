import React from 'react'
import Section1 from '../../components/Home/section1'
import Section2 from '../../components/Home/section2'
import HomeLesson from '../../components/Home/homelesson'

const Home = () => {
  return (
    <div className=''>
        <Section1/>
        <Section2/>
        <HomeLesson/>
    </div>
  )
}

export default Home


