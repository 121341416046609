import React from 'react'
import {BiMessageSquareEdit, BiSolidUser} from 'react-icons/bi'
import AddressUpdate from '../../../components/Modal/Update/address'

const AddressComp = ({data}) => {
  return (
    <div className=''>
        <div className='bg-[#274595] rounded-xl p-8 h-full'>
            <div className='flex justify-between '>
                <div className='flex items-center gap-4 flex-justify-between'>
                    <div className='bg-[#F3D080] rounded-full p-2'>
                        <BiSolidUser color='white' size={25}/>
                    </div>
                    <div className='text-white'>
                        <p className='text-opacity-75 text-lg'>Хаягийн мэдээлэл</p>
                        <h1 className='font-bold text-xl'>Гэрийн хаяг</h1>
                    </div>
                </div>
                <div>
                    <AddressUpdate data={data}/>
                </div>
            </div>
            <div className='text-white'>
                <p className='text-opacity-75 text-lg mt-4'>Дүүрэг / Аймаг</p>
                <h1 className='font-bold text-xl'>
                    {
                        data.duureg ?
                        data.duureg 
                        :
                        <h1 className='font-bold text-xl'>
                            ...
                        </h1>
                    }
                </h1>

                <p className='text-opacity-75 text-lg mt-4'>Хороо / Сум</p>
                <h1 className='font-bold text-xl'>
                    {
                        data.khoroo ?
                        data.khoroo 
                        :
                        <h1 className='font-bold text-xl'>
                            ...
                        </h1>
                    }
                </h1>

                <p className='text-opacity-75 text-lg mt-4'>Тоот / Баг</p>
                <h1 className='font-bold text-xl'>
                    {
                        data.toot ?
                        data.toot 
                        :
                        <h1 className='font-bold text-xl'>
                            ...
                        </h1>
                    }
                </h1>

                <p className='text-opacity-75 text-lg mt-4'>Дэлгэрэнгүй хаяг</p>
                <h1 className='font-bold text-xl'>
                    {
                        data.address ?
                        data.address 
                        :
                        <h1 className='font-bold text-xl'>
                            ...
                        </h1>
                    }
                </h1>
            </div>
        </div>
    </div>
  )
}

export default AddressComp