import React, { useContext, useEffect, useState } from 'react'
import { userTracksuri } from '../../../uri';
import axios from 'axios' 
import {AuthContext} from '../../../context/auth'
import {useToast, Select, Spinner} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Pagination from '../../../components/Pagination';
import Dateformat from '../../../components/Dateformat';
import AddTrack from '../../../components/Modal/addTrack';
import DeleteModal from '../../../components/Modal/delete';
import AllDelivery from '../../../components/Modal/alldelivery'
import DelivRemoveReq from '../../../components/Modal/delivRemove';
import ProductsCard from '../../../components/Card/products';

const Orders = () => {
  const {currentUser,logout} = useContext(AuthContext);
  const [path, setPath] = useState(200)
  const [prod, setProd] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(1);
  const [limit, setLimit] = useState(25);
  const[load, setLoad] = useState(false)
  const[count, setCount] = useState()
  const [alldata, setAlldata] = useState()
  const [delivPay, setDelivPay] = useState()
  const [allPay, setAllpay] = useState(0)
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  },[page, path]);

  const fetchData = async () => {
    setLoad(true);
    try{
      const res = await axios.get(userTracksuri+`?page=${page}&limit=${limit}&search=${search}&asdf=${currentUser}&type=${path}`);
      setCount(res.data.pagination.totalPage);
      setDelivPay(res.data.pay[0].deliveryPay);
      setAlldata(res.data.pagination.alldata);
      setProd(res.data.data);
      setLoad(false);

      const responseData = res.data.data;
      const totalSum = responseData.reduce((accumulator, item) => {
        return accumulator + parseInt(item.pay, 10); 
      }, 0);
      setAllpay(totalSum);

    }catch(err){
      console.log(err);
      setLoad(false);
      toast({title: 'Та дахин нэвтэрнэ үү',description: "Токен хугацаа дууссан байна.",status: 'error',duration: 3000,isClosable: true,})
      logout();
      navigate("/login")
    }
  };

  const callback =()=> {
    fetchData();
  }

  const changePage = (page) => {
    setPage(page);
  };

  const handlePathChange = (event) => {
    setPath(event.target.value);
  };

  function CopyDans(){
    toast({title: 'Хууллаа',status: 'success',duration: 3000,isClosable: true,})
  }

  return (
    <div className='font-Roboto '>
    <div>
      <h1 className='font-semibold text-xl'>Миний захиалгууд</h1>
      {/* <div className='hidden md:block'>
        <div className='flex items-center gap-4 mt-2 text-sm text-black uppercase'>
          <p onClick={() => setPath(200)} className={path === 200 ? 'font-semibold border-b-[3px] border-indigo-600 cursor-pointer pb-2': 'hover:font-semibold cursor-pointer pb-2'}>Бүгд</p>
          <p onClick={() => setPath(1)} className={path === 1 ? 'font-semibold border-b-[3px] border-indigo-600 cursor-pointer pb-2': 'hover:font-semibold cursor-pointer pb-2'}>Бүртгүүлсэн</p>
          <p onClick={() => setPath(2)} className={path === 2 ? 'font-semibold border-b-[3px] border-indigo-600 cursor-pointer pb-2': 'hover:font-semibold cursor-pointer pb-2'}>Хүлээн авсан</p>
          <p onClick={() => setPath(3)} className={path === 3 ? 'font-semibold border-b-[3px] border-indigo-600 cursor-pointer pb-2': 'hover:font-semibold cursor-pointer pb-2'}>Монголд ирсэн</p>
          <p onClick={() => setPath(10)} className={path === 10 ? 'font-semibold border-b-[3px] border-indigo-600 cursor-pointer pb-2': 'hover:font-semibold cursor-pointer pb-2'}>Бүртгүүлсэн хүргэлт</p>
          <p onClick={() => setPath(20)} className={path === 20 ? 'font-semibold border-b-[3px] border-indigo-600 cursor-pointer pb-2': 'hover:font-semibold cursor-pointer pb-2'}>Баталгаажсан хүргэлт</p>
        </div>
      </div>
      <div className='w-56 mt-2'>
        <AddTrack callback={callback}/>
      </div> */}
      <div className='flex justify-between'>
        <div className=' w-1/3 mt-2'>
          <Select className='shadow-lg' bg='white' onChange={handlePathChange}>
            <option value={200}>Бүгд</option>
            <option value={1}>Бүртгүүлсэн</option>
            <option value={2}>Хүлээн авсан</option>
            <option value={3}>Монголд ирсэн</option>
            <option value={10}>Бүртгүүлсэн хүргэлт</option>
            <option value={20}>Баталгаажсан хүргэлт</option>
          </Select>
        </div>
        <AddTrack callback={callback}/>
      </div>
    
      {
        path === '3' ?
        <div className='flex items-center flex-wrap gap-2 mt-2'>
          <AllDelivery callback={callback}/>
          <p>Хүргэлтээр авах боломжтой</p>
          <p className='font-bold'>{prod.length}</p>
          <p>бараа Төлбөр: </p>
          
          <p className='font-bold'>{allPay}</p>
        </div>
        :''
      }
      {
        path === '10' ?
        <div className='flex items-center flex-wrap gap-2 mt-2'>
          <DelivRemoveReq callback={callback}/>
        </div>
        :''
      }
      {
        path === '20' &&
        <div className='flex justify-between items-center font-semibold bg-orange-400 mr-2 mt-2 rounded text-white py-1 px-2'>
          <h1 className=''>
            Карго {allPay} + хүргэлт {delivPay} = нийт {parseInt(allPay) + parseInt(delivPay)}</h1>
            <div className='flex items-center'>
              <h1 className='mr-2'>Шилжүүлэх данс</h1>
              <img className='h-4 mr-2' src='https://play-lh.googleusercontent.com/Aw4bwCDJgAzu6AFAbbcfCFpheVMB6ZKiEM3JlrJ3cAM65fK-1QaTZZs_Vk4UFBzykQ'/>
              <p>5070147955</p>
              <CopyToClipboard text='5070147955'>
                <p onClick={CopyDans} className='ml-2 bg-gray-200 text-gray-700 px-2 py-1 text-xs rounded hover:bg-gray-300 cursor-pointer'>данс хуулах</p>
              </CopyToClipboard>
            </div>
        </div>
      }
      <div className="flex flex-col mr-2 mt-2">
        <div className="overflow-x-auto shadow-md sm:rounded-lg hidden md:block">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700 table table-responsive">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th scope="col" className="p-4 text-sm font-normal">
                      No
                    </th>
                    <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                      Трак код
                    </th>
                    <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                      Тэмдэглэл
                    </th>
                    <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                      Огноо
                    </th>
                    <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                      <p>Төлбөр</p>
                    </th>
                    <th scope="col" className="p-4"></th>
                  </tr>
                </thead>
                {
                  load?
                  <div className='flex items-center justify-center  p-4'>
                    <Spinner/>
                    <h1 className=''>Уншиж байна ...</h1>
                  </div>
                  :
                  <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                    {
                      prod.map((data, index) => {
                        return(
                          <tr className="hover:bg-gray-100 dark:hover:bg-gray-700" key={index}>
                            <td className="p-2 w-4">
                              -
                            </td>
                            <td className="py-2 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">{data.trackCode}</td>
                            <td className="py-2 px-6 text-sm font-medium whitespace-nowrap dark:text-white text-blue-700">
                              {data.note}
                            </td>
                            <td className="py-2 px-6 text-sm text-left whitespace-nowrap text-green-700 font-semibold">
                              <Dateformat dateString={data.date}/>
                            </td>
                            <td className="py-2 px-6 text-sm text-left whitespace-nowrap text-green-700 font-semibold">
                              {data.pay}
                            </td>
                            <td className="py-2 px-6 text-sm font-medium whitespace-nowrap">
                              {
                                data.type === '1' &&
                                <div className='flex items-center justify-start'>
                                  <div className='h-2.5 w-2.5 rounded-full bg-gray-400 mr-1'></div>
                                  <h1 className='uppercase'>Бүртгүүлсэн</h1>
                                  <DeleteModal id={data.id} callback={callback}/>
                                </div>
                              }
                              {
                                data.type === '2' &&
                                <div className='flex items-center justify-start'>
                                  <div className='h-2.5 w-2.5 rounded-full bg-indigo-600 mr-1'></div>
                                  <h1 className='uppercase'>Хүлээж авсан</h1>
                                </div>
                              }
                              {
                                data.type === '3' &&
                                <div className='flex items-center justify-start'>
                                  <div className='h-2.5 w-2.5 rounded-full bg-green-600 mr-1'></div>
                                  <h1 className='uppercase'>Ирсэн</h1>
                                </div>
                              }
                              {
                                data.type === '4' &&
                                <div className='flex items-center justify-start'>
                                  <div className='h-2.5 w-2.5 rounded-full bg-green-800 mr-1'></div>
                                  <h1 className='uppercase'>Эзэндээ очсон</h1>
                                </div>
                              }
                              {
                                data.type === '10' &&
                                <div className='flex items-center justify-start'>
                                  <div className='h-2.5 w-2.5 rounded-full bg-green-800 mr-1'></div>
                                  <h1 className='uppercase'>Бүртгүүлсэн хүргэлт</h1>
                                </div>
                              }
                              {
                                data.type === '20' &&
                                <div className='flex items-center justify-start'>
                                  <div className='h-2.5 w-2.5 rounded-full bg-green-800 mr-1'></div>
                                  <h1 className='uppercase'>Баталгаажсан хүргэлт</h1>
                                </div>
                              }
                            </td>
                        </tr>
                        )
                      })
                    }
                  </tbody>
                }
              </table>
              {
                prod.length === 0 &&
                <div className='bg-white text-center flex items-center justify-center'>
                  <img className='h-24' src='https://i.pinimg.com/originals/5d/35/e3/5d35e39988e3a183bdc3a9d2570d20a9.gif'/>
                  <h1>Хоосон байна.</h1>
                </div>
              }
                <Pagination initialPage={1} total={count} alldata={alldata} onChange={changePage}/>
            </div>
          </div>
        </div>

        <div className='md:hidden'>
          {
            load?
            <div className='flex items-center justify-center  p-4'>
              <Spinner/>
              <h1 className=''>Уншиж байна ...</h1>
            </div>
            :
            <>
              {
                prod.map((data, index) => {
                  return(
                    <ProductsCard data={data} key={index} callback={callback}/>
                  )
                })
              }
            </>
          }
          {
            prod.length === 0 &&
            <div className='bg-white text-center flex items-center justify-center'>
              <img className='h-24' src='https://i.pinimg.com/originals/5d/35/e3/5d35e39988e3a183bdc3a9d2570d20a9.gif'/>
              <h1>Хоосон байна.</h1>
            </div>
          }
          <Pagination initialPage={1} total={count} alldata={alldata} onChange={changePage}/>
        </div>
      </div>
      
    </div>
  </div>
  )
}

export default Orders