import {Route, Routes} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ServiceProcedure from './pages/ServiceProcedure';
import Lessons from './pages/Lessons';
import Login from './pages/Login'
import Register from './pages/Register'
import ResetPass from './pages/ResetPass';
import Account from './pages/Account';
import AccDashboard from './pages/Account/Accs/dashboard';
import Orders from './pages/Account/Accs/orders';
import ActivatePage from './pages/Activate/index'
import Alert from './components/Alert';
import Address from './pages/Address';

function App() {
  return (
    <>
      <ToastContainer/>
      <Navbar/>
      <Alert/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/service-procedure' element={<ServiceProcedure/>}/>
        <Route path='/lessons' element={<Lessons/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/forget-password' element={<ResetPass/>}/>
        <Route path='/activate/:token' element={<ActivatePage/>}/>
        <Route path='/address' element={<Address/>}/>

        <Route path="/account" element={<Account />}>
          <Route index element={<AccDashboard/>}/>
          <Route path="orders" element={<Orders/>}/>
        </Route> 
        
      </Routes>
      <Footer/>
    </>
  );
}

export default App;