import React, { useContext, useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Input,
    useToast,
    Spinner,
  } from '@chakra-ui/react'
  import {BiMessageSquareEdit} from 'react-icons/bi'
import { AuthContext } from '../../../context/auth'
import { getuserupdate } from '../../../uri'
import axios from 'axios'

const PersonalUpdate = ({data}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [load, setLoad] = useState(false);
    const toast = useToast();
    const {currentUser, logout} = useContext(AuthContext);

    useEffect(() => {
        setName(data.tabaoName);
        setPhone(data.phoneNo);
        setEmail(data.email);
    },[data]);

    const Submit = async () => {
        setLoad(true)
        try{
          const res = await axios.post(getuserupdate + currentUser, {tabaoName: name, phoneNo: phone, email: email, asdf:currentUser })
          if(res.status === 200){
            setLoad(false)
            window.location.reload();
            toast({title: 'Мэдээлэл шинчиллээ',description: "Хурд карго -г сонгодогт баярлалаа",status: 'success',duration: 3000,isClosable: true,})
          }
        }catch(err){
          console.log(err)
          setLoad(false)
          logout();
        }
      };

  return (
    <>
        <div onClick={onOpen} className='bg-slate-100 rounded-full p-2 cursor-pointer hover:bg-slate-300'>
            <BiMessageSquareEdit color='#274595' size={16}/>
        </div>

        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Хувийн мэдээлэл шинчлэх</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
             <div className='font-Roboto'>
                <div className='mt-2'>
                    <h1>Хаягийн нэр</h1>
                    <Input onChange={(e)=> setName(e.target.value)} value={name}/>
                </div>
                <div className='mt-2'>
                    <h1>Имейл хаяг</h1>
                    <Input onChange={(e)=> setEmail(e.target.value)} value={email}/>
                </div>
                <div className='mt-2'>
                    <h1>Утасны дугаар</h1>
                    <Input onChange={(e)=> setPhone(e.target.value)} value={phone}/>
                </div>
             </div>
             
            </ModalBody>

            <ModalFooter>
            <Button variant='ghost' mr={3} onClick={onClose}>
                Болих
            </Button>
            <Button colorScheme='blue' onClick={Submit}>
                {
                    load&&
                    <Spinner className='mr-2'/>
                }
                Хадгалах
            </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    </>
  )
}

export default PersonalUpdate