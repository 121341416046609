

// const mainUrl = "http://localhost:5000";
const mainUrl = "https://khurd-back.vercel.app"

//auth urls
export const loginUri = mainUrl + "/auth/login";
export const registerUri = mainUrl + "/auth/register";
export const logoutUri = mainUrl + "/auth/logout";
export const forgetUri = mainUrl + "/auth/forget";
export const resetPassUri = mainUrl + "/auth/resetpass";

//user uri
export const getuseruri = mainUrl + "/user/";
export const getuserupdate = mainUrl + "/user/userupdate/";
export const getuseraddressupdate = mainUrl + "/user/addressupdate/";
export const userTracksuri = mainUrl + "/user/tracks";
export const usertrackadd = mainUrl + "/user/track/add";
export const deletetrack = mainUrl + "/user/track/delete/";
export const deliveryRegisteruri = mainUrl + "/delivery/bvrtgel";

export const userdelivcount = mainUrl + "/delivery/alldeliv/";
export const delivremoveuri = mainUrl + "/delivery/delivRemove";

export const getlesson = mainUrl + "/other/lesson";
export const getContenttouri = mainUrl + "/other/contentget/";
export const getcountsuserprod = mainUrl + "/other/usercounts";
export const getalerturi = mainUrl + "/other/get/alert";
export const getaddressuri = mainUrl + "/other/get/address";
