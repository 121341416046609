import React, { useContext, useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Input,
    useToast,
    Spinner,
    Textarea,
  } from '@chakra-ui/react'
  import {BiMessageSquareEdit} from 'react-icons/bi'
import { AuthContext } from '../../../context/auth'
import { getuseraddressupdate, getuserupdate } from '../../../uri'
import axios from 'axios'

const AddressUpdate = ({data}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [duureg, setDuureg] = useState();
    const [khoroo, setKhoroo] = useState();
    const [toot, setToot] = useState();
    const [address, setAddress] = useState();

    const [load, setLoad] = useState(false);
    const toast = useToast();
    const {currentUser, logout} = useContext(AuthContext);

    useEffect(() => {
        setDuureg(data.duureg);
        setKhoroo(data.khoroo);
        setToot(data.toot);
        setAddress(data.address);
    },[data]);

    const Submit = async () => {
        setLoad(true)
        try{
          const res = await axios.post(getuseraddressupdate + currentUser, {duureg: duureg, khoroo: khoroo, toot: toot, address: address, asdf:currentUser })
          if(res.status === 200){
            setLoad(false)
            window.location.reload();
            toast({title: 'Мэдээлэл шинчиллээ',description: "Хурд карго -г сонгодогт баярлалаа",status: 'success',duration: 3000,isClosable: true,})
          }
        }catch(err){
          console.log(err)
          setLoad(false)
          logout();
        }
      };

  return (
    <>
        <div onClick={onOpen} className='bg-slate-100 rounded-full p-2 cursor-pointer hover:bg-slate-300'>
            <BiMessageSquareEdit color='#274595' size={16}/>
        </div>

        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Гэрийн хаяг шинчлэх</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
             <div className='font-Roboto'>
                <div className='mt-2'>
                    <h1>Дүүрэг / Аймаг</h1>
                    <Input onChange={(e)=> setDuureg(e.target.value)} value={duureg} placeholder='Оруулах'/>
                </div>
                <div className='mt-2'>
                    <h1>Хороо / Баг</h1>
                    <Input onChange={(e)=> setKhoroo(e.target.value)} value={khoroo} placeholder='Оруулах'/>
                </div>
                <div className='mt-2'>
                    <h1>Тоот / Хаяг</h1>
                    <Input onChange={(e)=> setToot(e.target.value)} value={toot} placeholder='Оруулах'/>
                </div>
                <div className='mt-2'>
                    <h1>Дэлгэрэнгүй хаяг</h1>
                    <Textarea onChange={(e)=> setAddress(e.target.value)} value={address} placeholder='Оруулах'/>
                </div>
             </div>
             
            </ModalBody>

            <ModalFooter>
            <Button variant='ghost' mr={3} onClick={onClose}>
                Болих
            </Button>
            <Button colorScheme='blue' onClick={Submit}>
                {
                    load&&
                    <Spinner className='mr-2'/>
                }
                Хадгалах
            </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    </>
  )
}

export default AddressUpdate