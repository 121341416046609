import React from 'react'

const Section2 = () => {
  return (
    <div className='flex justify-center mt-[-100px] z-20 pb-20'>
        <div className='grid xs:grid-cols-1 md:grid-cols-3 justify-between  w-2/3 font-Roboto bg-white rounded-2xl shadow-xl '>
          <div className=' py-16 px-6 cursor-pointer group hover:bg-gradient-to-r hover:from-[#7B66FF] hover:to-[#76DEFF] rounded-l-2xl hover:text-white'>
            <img className='h-12' src='./icons/prod.png'/>
            <h1 className='text-xl mt-2 font-bold'>Танилцуулга</h1>
            <p className='text-sm mt-2'>Манай карго нь Хятад, Монгол гэсэн 2 үндсэн чиглэлд тээвэрийн үйлчилгээ үзүүлж байна</p>
          </div>

          <div className=' py-16 px-6 cursor-pointer group hover:bg-gradient-to-r hover:from-[#7B66FF] hover:to-[#76DEFF] hover:text-white'>
            <img className='h-12' src='./icons/word.png'/>
            <h1 className='text-xl mt-2 font-bold'>Бараа бүртгэл</h1>
            <p className='text-sm mt-2'>Та захиалсан бараагаа www.khurdcargo.mn вебсайтаар хянах боломжтой</p>
          </div>

          <div className=' py-16 px-6 cursor-pointer group hover:bg-gradient-to-r hover:from-[#7B66FF] hover:to-[#76DEFF] rounded-r-2xl hover:text-white'>
            <img className='h-12' src='./icons/car.png'/>
            <h1 className='text-xl mt-2 font-bold'>Найдвартай тээвэрлэлт</h1>
            <p className='text-sm mt-2'>Таны бараа бүтээгдэхүүнийг тээвэрлэхдээ аюулгүй байдлыг бүрэн хангасан</p>
          </div>
        </div>
    </div>
  )
}

export default Section2