import React from 'react'
import {FaPlayCircle} from "react-icons/fa"
import ModalLesson from '../Modal/lessonmodal'

const LessonCard = ({data}) => {
  return (
    <div className='cursor-pointer'>
        <div className='bg-gradient-to-b from-[#244D9C] rounded-2xl p-6 h-96 shadow-2xl hover:bg-indigo-600 group transition ease-in-out delay-150'>
            <h1 className='text-white mt-10 text-xl font-bold font-Roboto text-center uppercase'>{data?.title}</h1>
            <div className='group-hover:block hidden'>
                <div className='flex justify-center mt-4 hover:animate-pulse'>
                    <ModalLesson data={data?.id}/>
                </div>
            </div>
        </div>
        <div className='flex justify-center'>
            <img className='relative h-60 z-20 mt-[-200px]' src='./icons/utas1.png'/>
        </div>
    </div>
  )
}

export default LessonCard
