

import { Input, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react'
import { useState } from 'react';
import {AiOutlineIdcard} from "react-icons/ai"
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { resetPassUri } from '../../uri';

const ActivatePage = () => {

  const { token } = useParams();
  const [user, setUser] = useState({ password: "", passwordConf: ""});
  const [err, setErr] = useState(null)
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const toast = useToast()

  const loadProfile = async () => {
    setLoad(true)
    if(user.password === user.passwordConf){
        try{
            const res = await axios.post(resetPassUri, {newPassword: user.password, resetPasswordLink: token})
            if(res.status === 200){
                setLoad(false)
                navigate("/login")
                toast({title: 'Амжилттай',description: "Таны нууц үг амжилттай солигдлоо",status: 'success',duration: 5000,isClosable: true,})
            }
        }catch(err){
            console.log(err)
            setLoad(false)
            navigate("/forget-password")
            return toast({title: 'Алдаа',description: "Таны баталгаажуулах хугацаа дууссан байна !",
            status: 'error',duration: 5000,isClosable: true,})
        }
    }else{
        setLoad(false)
        return toast({title: 'Алдаа',description: "Таны орууласан нууц үгүүд таарахгүй байна !",
        status: 'error',duration: 5000,isClosable: true,})
    }
  }

  return (
    <div className="mt-20">
    <div className="flex justify-center bg-slate-200">
      <div className="my-10 bg-white shadow-lg rounded-md">
        <div className="px-10 py-8 font">
          <h1 className="text-2xl text-center uppercase font-bold">Шинэ нууц үг</h1>
          <p className="text-sm text-center mt-4">Та өөрийн цаашид ашиглах нууц үгээ оруулна уу </p>
           <div className='mt-6'>
            <p className='text-sm mb-2'>Нууц үг</p>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<AiOutlineIdcard color='gray' size={25} />}
              />
              <Input type='password' placeholder='Нууц үг' 
               value={user.email}
               onChange={ e => setUser({...user, password:e.target.value})}/>
            </InputGroup>
            <p className='text-sm mb-2 mt-2'>Нууц үг давтах</p>
            <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                children={<AiOutlineIdcard color='gray' size={25} />}
              />
              <Input type='password' placeholder='Нууц үг' 
               value={user.email}
               onChange={ e => setUser({...user, passwordConf:e.target.value})}/>
            </InputGroup>
              <div className='text-xs text-red-600'>{err}</div>
            <div className='mt-8 '>
              {
                load?
                <div className='text-center bg-blue-500 hover:bg-blue-600 rounded-md text-white uppercase py-2 cursor-pointer'
                >Илгээж байна...</div>
                :
                <div className='text-center bg-blue-500 hover:bg-blue-600 rounded-md text-white uppercase py-2 cursor-pointer'
                onClick={loadProfile}>Нууц үг илгээх</div>
              }
              <Link to="/login">
              <p className='text-center text-blue-600 mt-2 uppercase'>Нэвтрэх</p>
              </Link>
            </div>
           </div>
        </div>
      </div>
    </div>
   </div>
  )
}

export default ActivatePage
